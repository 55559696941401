import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCheckCircle as ToggleApprovalIcon } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import styles from './List.module.scss'
import List from 'components/shared/Lists/List'
import { DatabaseService } from 'services'
import { getRoute } from 'utils/index'
import UserBlurb from 'components/Users/UserBlurb'

function UserListItem(props) {
  const { data = {} } = props
  const [working, setWorking] = useState(false)

  const {
    $id: id,
    $ref,
    approved = false,
    email = '',
    phone = '',
    phoneNumber = '',
    newsletterSubscribed = true,
    socialVerified = false,
    paymentSources = [],
    sources = [], // backward compatibility
    description = '',
    stripe_scope = '',
    picture = '',
    firstName = '',
    lastName = '',
    city = '',
    createdAt = null,
  } = data
  // When approved changes set working to false
  useEffect(() => {
    setWorking(false)
  }, [approved])

  return (
    <div className={styles.itemListItem}>
      <div className={styles.itemImages}>
        <a href={getRoute('PROFILE', { id })} target="blank">
          <img src={picture} />
        </a>
      </div>
      <div className={styles.itemInfo}>
        <div className={styles.topLine}>
          <a href={getRoute('PROFILE', { id })} target="blank">
            <span>
              {firstName} {lastName}
            </span>
          </a>{' '}
          ({email}
          {phone && ', ' + phone}
          {phoneNumber && ', ' + phoneNumber})
        </div>
        <div className={styles.midLine}>
          {city && <span>{city}</span>}
          <span>{createdAt && moment(createdAt.toDate()).fromNow()}</span>
        </div>
        <div className={styles.bline}>
          <span>Social verified: {socialVerified ? 'True' : 'False'}</span>
        </div>
        <div className={styles.bline}>
          <span>
            Has rented:{' '}
            {paymentSources.length || sources.length ? 'True' : 'False'}
          </span>
        </div>
        <div className={styles.bline}>
          <span>
            Completed stripe onboarding: {stripe_scope ? 'True' : 'False'}
          </span>
        </div>
        <div className={styles.bline}>
          {description && <span>{description}</span>}
        </div>
      </div>
    </div>
  )
}

export default function UserList(props) {
  const { pending, initialItems = [] } = props
  const [users, setUsers] = useState(initialItems)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  function updateUsers(newUsers) {
    setUsers(newUsers)
    setLoading(false)
  }

  useEffect(() => {
    if (initialItems.length) {
      return
    }

    let collection = DatabaseService.firestore.collection(
      DatabaseService.COLLECTIONS.users
    )

    return collection.orderBy('createdAt', 'desc').onSnapshot({
      next: ({ docs } = {}) => {
        updateUsers(
          docs.map(doc => ({
            ...doc.data(),
            $id: doc.id,
            $ref: doc.ref,
          }))
        )
      },
      error: error => {
        console.error(error)
        setLoading(false)
        setError(error)
      },
    })
  }, [])

  return (
    <List
      items={users}
      error={error}
      loading={loading}
      itemComponent={UserListItem}
    />
  )
}
