import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle as ToggleApprovalIcon,
  faSpinner as LoadingIndicatorIcon,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import styles from './List.module.scss'
import { DatabaseService } from 'services'
import List from 'components/shared/Lists/List'
import { getRoute } from 'utils/index'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function ItemListItem(props) {
  const { data = {} } = props
  const [working, setWorking] = useState(false)

  const {
    $id: id,
    $ref,
    approved = false,
    city = '',
    state = '',
    name = '',
    pictures = [],
    user = {},
    user_name = '',
    description,
    createdAt = null,
    price_d = 0,
  } = data
  const user_id = user.id
  // When approved changes set working to false
  useEffect(() => {
    setWorking(false)
  }, [approved])

  const toggleApproval = async () => {
    if (working) {
      return
    }
    /** @type {firebase.firestore.DocumentReference} */
    const ref = $ref
    if (!ref) {
      throw 'Cannot set ref. ref was not found'
    }
    setWorking(true)
    try {
      await ref.update({ approved: !approved })
    } catch (error) {
      setWorking(false)
    }
  }

  return (
    <div className={styles.itemListItem}>
      <div className={styles.itemImages}>
        <a href={getRoute('ITEM', { id })} target="blank">
          <img src={pictures[0]} />
        </a>
      </div>
      <div className={styles.itemInfo}>
        <div className={styles.topLine}>
          <span>
            <a
              href={getRoute('ITEM', { id })}
              target="blank"
              className={styles.title}
            >
              {name}
            </a>
          </span>{' '}
        </div>
        <div className={styles.midLine}>
          <span>
            {city}, {state}
          </span>
          <span>approved: {approved}</span>
          <span>{formatter.format(price_d / 100)}</span>
          <span>{createdAt && moment(createdAt.toDate()).fromNow()}</span>
          <span>
            <a href={getRoute('PROFILE', { id: user_id })} target="blank">
              {user_name}
            </a>
          </span>
        </div>
        <div className={styles.botLine}>{description}</div>
      </div>
      <div className={styles.itemActions}>
        <div className={styles.action} onClick={toggleApproval}>
          {working ? (
            <Icon color={'gray'} spin icon={LoadingIndicatorIcon} />
          ) : (
            <Icon
              color={approved ? '#49b882' : 'rgba(0,0,0,0.1)'}
              icon={ToggleApprovalIcon}
            />
          )}
        </div>
      </div>
    </div>
  )
}
/**
 * Load and display a list of items directly from
 * firebase. We might consider using algolia for
 * this, but I hate looking at their awful documentation.
 *
 * This list automatically updates when new items are added,
 * but I haven't put any thought into how exactly it should do
 * that...
 * @param {any} props
 */
export default function ItemList(props) {
  const { pending, initialItems = [] } = props
  const [items, setItems] = useState(initialItems)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  function updateItems(newItems) {
    setItems(newItems)
    setLoading(false)
  }

  useEffect(() => {
    // don't load from firebase if items are supplied to the the component
    if (initialItems.length) {
      return
    }
    // load items from the database
    /** @type {firebase.firestore.CollectionReference} */
    let collection = DatabaseService.firestore.collection(
      DatabaseService.COLLECTIONS.items
    )
    collection.orderBy('createdAt', 'desc')

    // Why is this causing error?
    // if (pending === true) {
    //   collection = collection.where('approved', '==', false)
    // }
    if (pending === false) {
      collection = collection.where('approved', '==', true)
    }
    return collection.orderBy('createdAt', 'desc').onSnapshot({
      next: ({ docs } = {}) => {
        updateItems(
          docs.map(doc => ({
            ...doc.data(),
            $id: doc.id,
            $ref: doc.ref,
          }))
        )
      },
      error: error => {
        console.error(error)
        setLoading(false)
        setError(error)
      },
    })
  }, [initialItems])

  return (
    <List
      items={items}
      error={error}
      loading={loading}
      itemComponent={ItemListItem}
    />
  )
}
