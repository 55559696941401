import React, { useState, useEffect } from 'react'
import Sidebar from 'react-sidebar'
import { navigate } from 'gatsby'
import { Match, Link } from '@reach/router'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import Melo from 'components/shared/Brand/Melo'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import styles from './_mod.module.scss'
import {
  RentalSection,
  ItemSection,
  UserSection,
} from 'components/ModPage/Sections'
import useUserRoles from 'utils/hooks/useUserRoles'
import { DatabaseService } from 'services'
// Page name because I presume this page won't keep its current name
const pn = '_mod'

function SidebarItem({ route, content, section } = props) {
  const isSelected =
    section.replace(/\W/g, '').toLowerCase() ===
    route.replace(/\W/g, '').toLowerCase()
  return (
    <li>
      <Link to={`${pn}${route}`} className={isSelected ? styles.selected : ''}>
        {content}
      </Link>
    </li>
  )
}

function CustomSidebar({ section } = {}) {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarHeader}>
        <Link to="/">
          <Melo color={'#fff'} />
        </Link>
      </div>
      <ul className={styles.sidebar}>
        <SidebarItem route={'/home'} content={'Home'} section={section} />
        <SidebarItem route={'/users'} content={'Users'} section={section} />
        <SidebarItem route={'/items'} content={'Items'} section={section} />
        <SidebarItem route={'/rentals'} content={'Rentals'} section={section} />
      </ul>
    </div>
  )
}

function CustomNavbar({ name = 'Home', docked = true, onToggleSidebar } = {}) {
  return (
    <div className={styles.navbar}>
      {!docked && (
        <div className={styles.menuToggle} onClick={onToggleSidebar}>
          <Icon icon={faBars} />
        </div>
      )}
      <h3>{name[0].toUpperCase() + name.slice(1)}</h3>
    </div>
  )
}

const sections = {
  default: '',
  rentals: <RentalSection />,
  users: <UserSection />,
  items: <ItemSection />,
}

function Section(props) {
  const { children } = props
  return <div className={styles.section}>{children}</div>
}

/**
 * Prototypal site administration dashboard.
 */
export default function AdminPage(props) {
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const isSidebarDocked = useMediaQuery(`(min-width: 800px)`)

  const roleMatches = useUserRoles(['admin'])

  useEffect(() => {
    if (roleMatches === false) {
      console.error('This route is for administrators only')
      navigate('/')
    }
  }, [roleMatches])

  function getSection({ match, ...props } = {}) {
    let section = sections.default
    if (match) {
      section = sections[match.section.toLowerCase()] || section
    }
    return <Section>{section}</Section>
  }

  // Role not yet loaded or role doesn't match
  if (!roleMatches) {
    return null
  }

  return (
    <Match path="/_mod/:section">
      {props => (
        <Sidebar
          sidebar={
            <CustomSidebar
              section={(props.match && props.match.section) || 'Home'}
            />
          }
          onSetOpen={val => setSidebarOpen(val)}
          open={isSidebarOpen}
          docked={isSidebarDocked}
        >
          <div className={styles.pageBody}>
            <CustomNavbar
              onToggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
              name={(props.match && props.match.section) || 'Home'}
              docked={isSidebarDocked}
            />
            {getSection(props)}
          </div>
        </Sidebar>
      )}
    </Match>
  )
}
